@tailwind base;
@tailwind components;
@tailwind utilities;

iframe {
    display: none;
}

body {
    height: 100vh;
    margin: 0;
    padding: 0;
    font-family: "Courier New", Courier, monospace;

}
